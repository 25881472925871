@import 'compass/css3';
@import 'colours';
@import 'custom/colours-custom';

body {
	height: calc(100% - 110px);
	padding-bottom: 200px; }

.table td input + label {
	float: none !important;
	&:before {
		margin-right: 0 !important; } }

.col-md-3 {
	.affix {
		max-height: 87vh;
		overflow-x: hidden;
		width: calc(25% - 30px);
		.table-container {
			height: 5em; }
		table {
			display: flex;
			flex-flow: column;
			height: 100%;
			width: 100%; }
		table thead {
			flex: 0 0 auto;
			width: calc(100% - 0.9em); }
		table tbody {
			flex: 1 1 auto;
			display: block;
			overflow-y: scroll; }
		table tbody tr {
			width: 100%; }
		table thead, table tbody tr {
			display: table;
			table-layout: fixed; } } }

.add-order, .edit-order {
	float: left;
	width: 98% !important;
	@include transition( 0.5s cubic-bezier(.02, .01, .47, 1) width );
	&.sidebar-open {
		width: 68.5% !important;
		@include transition( 0.5s cubic-bezier(.02, .01, .47, 1) width ); } }

@media all and (max-width: 1050px) {
	.col-md-3 .affix {
		max-height: none;
		position: relative;
		width: 100%; } }

@media all and (min-width: 1200px) {
	.sidebar-open {
		.col-lg-1 {
			width: 16.66666667%; }
		.col-lg-2 {
			width: 25%; }
		.col-md-2 {
			width: 25%; } } }

@media all and (max-width: 1200px) and (min-width: 976px) {
	.sidebar-right-toggle {
		width: 3% !important; }
	.add-order {
		width: 97% !important; }
	.sidebar-open {
		.col-md-3 {
			width: 33.333333333%; }
		.col-md-6 {
			width: 100%; } } }

@media all and (max-width:976px) {
	.col-max-3 {
		max-width: 25% !important;
		width: 25% !important;
		float: left; }
	.col-max-4 {
		max-width: 33.3333333% !important;
		width: 33.3333333% !important;
		float: left; }
	.sidebar-right-toggle {
		width: 4% !important; }
	.add-order {
		width: 96% !important; } }

@media all and (max-width:1350px) {
	.sidebar {
		width: 100% !important; } }

@media screen and (max-width: 768px) {
	table:not(.no-responsive) tbody tr, .table:not(.no-responsive) tbody tr {
		padding-left: 0;
		@include box-shadow(0 0 0 transparent); } }

label {
	font-weight: normal;
	margin: 2px 0;
	img {
		width: auto !important; } }

#sections .panel {
	.panel-heading {
		h3 {
			font-size: 18px;
			line-height: 30px; } }
	.panel-body {
		p {
			font-size: 13px; } }
	.panel-heading + .panel-body {
		border-top: 0; } }


#sections, .sections {
	.panel-body {
		border-top: none; }
	.panel-heading {
		border-radius: 0;
		background: linear-gradient($white, $grey-lightest);
		border-bottom: 1px solid $grey-light;
		&.collapsed {
			border-bottom: 0px solid transparent;
			@include transition(0.5s ease border-bottom); } }
	.panel-body + .panel-heading {
		border-top: 0px solid transparent; } }

.sidebar {
	width: 30%;
	float: right;
	height: 100%;
	position: fixed;
	z-index: 99;
	background: $grey-lightest;
	border-left: 1px solid $grey-light;
	@include box-shadow(0 10px 15px rgba($grey, 0.5));
	top: 0;
	right: 0;
	&.left {
		float: left;
		left: 0; }
	&.collapsed {
		background: $nav-background;
		@include transition(0.5s ease background); }
	.sidebar-container {
		overflow-y: scroll;
		overflow-x: hidden;
		background: $grey-lightest;
		height: 100%;
		border: none;
		@include box-shadow(0 0 0);
		z-index: 2;
		.panel {
			&:last-child {
				padding-bottom: 2rem; } }
		.panel-body {
			border-top: none; }
		.panel-heading {
			border-radius: 0;
			background: linear-gradient($white, $grey-lightest);
			border-bottom: 1px solid $grey-light;
			border-right: 1px solid $grey-light;
			&.collapsed {
				border-bottom: 0px solid transparent;
				@include transition(0.5s ease border-bottom); } }
		.panel-body + .panel-heading {
			border-top: 1px solid $grey-light; } } }

.sidebar-toggle {
	font-size: 34px;
	position: fixed;
	top: 50%;
	float: right;
	z-index: 2;
	@include transform(rotate(0));
	margin: 0 1rem;
	color: $primary;
	@include transition(0.3s ease transform, 2s ease color, 0.5s ease opacity);
	&.right {
		right: 1rem; }
	&.left {
		float: left;
		left: 0; }
	&:hover {
		opacity: 0.8;
		@include transition(0.2s ease opacity); }
	&.collapsed {
		margin-right: 0.25em;
		left: 30%;
		@include transform(rotate(180deg));
		@include transition(0.1s ease transform, 0.1s ease color, 0.5s ease opacity); } }

@media (max-width: 1350px) {
	.sidebar .sidebar-container {
		padding: 0 4rem 0 1rem;
		.col-md-12 {
			padding: 0; } }
	.sidebar-toggle {
		left: auto;
		z-index: 5000;
		&.collapsed {
			left: auto;
			right: 1.5rem; } } }

.section-form {
	label {
		line-height: 20px;
		position: relative;
		top: -3px; } }


.button-container {
	padding-bottom: 15px; }
label {
	img {
		max-height: 3rem; } }
@media screen {
	input[type="checkbox"]:not(.disable-checkbox-styling),
	input[type="radio"]:not(.disable-checkbox-styling) {
		display: none;
		+ label {
			position: relative;
			text-indent: 4px;
			&:hover {
				cursor: pointer; }
			&:before {
				z-index: 1;
				display: block;
				content: '';
				float: left;
				width: 22px;
				height: 22px;
				border-radius: 3px;
				border: 1px solid $grey-light;
				margin-right: 5px;
				background: transparent;
				-webkit-transition: 0.4s ease all;
				transition: 0.4s ease all; } }
		&:checked {
			+ label {
				&:before {
					border-color: #65ac46; }
				&:after {
					z-index: 2;
					font-size: 18px;
					line-height: 22px;
					content: '\2713';
					color: #65ac46;
					-webkit-transition: 0.2s ease all;
					transition: 0.2s ease all;
					width: 22px;
					text-align: center;
					position: absolute;
					left: 0;
					text-indent: 0;
					top: 0;
					border-radius: 3px; } } }
		&:disabled {
			+ label {
				cursor: default;
				&:before {
					border-color: #9f9f9f;
					background: #f5f5f5; }
				&:after {
					color: #4a4a4a;
					background: #ccc;
					border: 1px solid #9f9f9f;
					height: 22px; } } } }
	input[type="radio"]:not(.disable-checkbox-styling) {
		+ label {
			&::before {
				border-radius: 50%; } }
		&:checked {
			+ label {
				&::after {
					width: 14px;
					height: 14px;
					background-color: #65ac46;
					border-radius: 50%;
					margin: 4px;
					content: '';
					left: 0; } } } } }
