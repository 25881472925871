// C O L O U R V A R I A B L E S

$white: #fff;
$black: #000;

// G R E Y
$grey: #b5b5b5;
$grey-b: darken($grey, 10);

$grey-light: lighten($grey, 5);
$grey-b-light: lighten($grey-b, 5);
$grey-lighter: lighten($grey, 15);
$grey-b-lighter: lighten($grey-b, 15);
$grey-lightest: lighten($grey, 25);
$grey-b-lightest: lighten($grey-b, 25);

$grey-dark: darken($grey, 5);
$grey-b-dark: darken($grey-b, 5);
$grey-darker: darken($grey, 15);
$grey-b-darker: darken($grey-b, 20);
$grey-darkest: darken($grey, 30);
$grey-b-darkest: darken($grey-b, 30);

// G R E E N
$green: #9BD665;
$green-b: darken($green, 10);

$green-light: lighten($green, 10);
$green-b-light: lighten($green-b, 10);
$green-lighter: lighten($green, 20);
$green-b-lighter: lighten($green-b, 20);
$green-lightest: lighten($green, 32);
$green-b-lightest: lighten($green-b, 32);

$green-dark: darken($green, 10);
$green-b-dark: darken($green-b, 10);
$green-darker: darken($green, 15);
$green-b-darker: darken($green-b, 15);
$green-darkest: darken($green, 35);
$green-b-darkest: darken($green-b, 35);

// L I G H T B L U E
$lightblue: #559edb;
$lightblue-b: darken($lightblue, 10);

$lightblue-light: lighten($lightblue, 10);
$lightblue-b-light: lighten($lightblue-b, 10);
$lightblue-lighter: lighten($lightblue, 20);
$lightblue-b-lighter: lighten($lightblue-b, 20);
$lightblue-lightest: lighten($lightblue, 35);
$lightblue-b-lightest: lighten($lightblue-b, 35);

$lightblue-dark: darken($lightblue, 10);
$lightblue-b-dark: darken($lightblue-b, 10);
$lightblue-darker: darken($lightblue, 20);
$lightblue-b-darker: darken($lightblue-b, 20);
$lightblue-darkest: darken($lightblue, 35);
$lightblue-b-darkest: darken($lightblue-b, 35);

// R E D
$red: #C62828;
$red-b: darken($red, 10);

$red-light: lighten($red, 10);
$red-b-light: lighten($red-b, 10);
$red-lighter: lighten($red, 20);
$red-b-lighter: lighten($red-b, 20);
$red-lightest: lighten($red, 30);
$red-b-lightest: lighten($red-b, 30);

$red-dark: darken($red, 10);
$red-b-dark: darken($red-b, 10);
$red-darker: darken($red, 20);
$red-b-darker: darken($red-b, 20);
$red-darkest: darken($red, 35);
$red-b-darkest: darken($red-b, 35);

// O R A N G E
$orange: #f0b23c;
$orange-b: darken($orange, 10);

$orange-light: lighten($orange, 10);
$orange-b-light: lighten($orange-b, 10);
$orange-lighter: lighten($orange, 20);
$orange-b-lighter: lighten($orange-b, 20);
$orange-lightest: lighten($orange, 35);
$orange-b-lightest: lighten($orange-b, 35);

$orange-dark: darken($orange, 10);
$orange-b-dark: darken($orange-b, 10);
$orange-darker: darken($orange, 20);
$orange-b-darker: darken($orange-b, 20);
$orange-darkest: darken($orange, 35);
$orange-b-darkest: darken($orange-b, 35);

// Y E L L O W
$yellow: #ffe164;
$yellow-b: darken($yellow, 10);

$yellow-light: lighten($yellow, 10);
$yellow-b-light: lighten($yellow-b, 10);
$yellow-lighter: lighten($yellow, 20);
$yellow-b-lighter: lighten($yellow-b, 20);
$yellow-lightest: lighten($yellow, 35);
$yellow-b-lightest: lighten($yellow-b, 35);

$yellow-dark: darken($yellow, 10);
$yellow-b-dark: darken($yellow-b, 10);
$yellow-darker: darken($yellow, 20);
$yellow-b-darker: darken($yellow-b, 20);
$yellow-darkest: darken($yellow, 35);
$yellow-b-darkest: darken($yellow-b, 35);

// P I N K
$pink: #ec407a;
$pink-b: darken($pink, 10);

$pink-light: lighten($pink, 10);
$pink-b-light: lighten($pink-b, 10);
$pink-lighter: lighten($pink, 20);
$pink-b-lighter: lighten($pink-b, 20);
$pink-lightest: lighten($pink, 35);
$pink-b-lightest: lighten($pink-b, 35);

$pink-dark: darken($pink, 10);
$pink-b-dark: darken($pink-b, 10);
$pink-darker: darken($pink, 20);
$pink-b-darker: darken($pink-b, 20);
$pink-darkest: darken($pink, 35);
$pink-b-darkest: darken($pink-b, 35);
