// C O L O U R V A R I A B L E S

// P R I M A R Y
$primary: #343536;
$primary-b: darken($primary, 10);
//
$primary-light: lighten($primary, 10);
$primary-b-light: lighten($primary-b, 10);
$primary-lighter: lighten($primary, 20);
$primary-b-lighter: lighten($primary-b, 20);
$primary-lightest: lighten($primary, 35);
$primary-b-lightest: lighten($primary-b, 35);
//
$primary-dark: darken($primary, 10);
$primary-b-dark: darken($primary-b, 10);
$primary-darker: darken($primary, 20);
$primary-b-darker: darken($primary-b, 20);
$primary-darkest: darken($primary, 35);
$primary-b-darkest: darken($primary-b, 35);

// S E C O N D A R Y
$secondary: #5bc0de;
$secondary-b: darken($secondary, 10);

$secondary-light: lighten($secondary, 10);
$secondary-b-light: lighten($secondary-b, 10);
$secondary-lighter: lighten($secondary, 20);
$secondary-b-lighter: lighten($secondary-b, 20);
$secondary-lightest: lighten($secondary, 35);
$secondary-b-lightest: lighten($secondary-b, 35);

$secondary-dark: darken($secondary, 10);
$secondary-b-dark: darken($secondary-b, 10);
$secondary-darker: darken($secondary, 20);
$secondary-b-darker: darken($secondary-b, 20);
$secondary-darkest: darken($secondary, 35);
$secondary-b-darkest: darken($secondary-b, 35);

// T E R T I A R Y
$tertiary: #49AFEB;
$tertiary-b: darken($tertiary, 10);

$tertiary-light: lighten($tertiary, 10);
$tertiary-b-light: lighten($tertiary-b, 10);
$tertiary-lighter: lighten($tertiary, 20);
$tertiary-b-lighter: lighten($tertiary-b, 20);
$tertiary-lightest: lighten($tertiary, 35);
$tertiary-b-lightest: lighten($tertiary-b, 35);

$tertiary-dark: darken($tertiary, 10);
$tertiary-b-dark: darken($tertiary-b, 10);
$tertiary-darker: darken($tertiary, 20);
$tertiary-b-darker: darken($tertiary-b, 20);
$tertiary-darkest: darken($tertiary, 35);
$tertiary-b-darkest: darken($tertiary-b, 35);

// B A C K G R O U N D S
$background: #fff;
$background-dark: darken($background, 5);
$background-darker: darken($background, 10);
$background-darkest: darken($background, 20);
$background-light: lighten($background, 5);
$background-lighter: lighten($background, 10);
$background-lightest: lighten($background, 15);

$grey-background: #f5f5f5;

// N A V B A C K G R O U N D
$nav-background: #3f4041;
$nav-background-dark: darken($nav-background, 10);
$nav-background-darker: darken($nav-background, 20);
$nav-background-darkest: darken($nav-background, 30);
$nav-background-light: lighten($nav-background, 10);
$nav-background-lighter: lighten($nav-background, 20);
$nav-background-lightest: lighten($nav-background, 40);
